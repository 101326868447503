@import '../../../styles/styles';

.homeownerSection {
  background-color: $light-cyan;
  background-image: url('/assets/images/homepage-homeowner.svg');
  background-position: 95% calc(100% + 0.25rem);
  background-repeat: no-repeat;
  background-size: 800px;

  @include mediaQuery(max, 1125px) {
    background-size: 70%;
  }

  @include mediaQuery(max, 1024px) {
    background-size: 65%;
  }

  @include mediaQuery(max, 800px) {
    background-size: 100%;
    padding-bottom: 15rem;
  }

  @include mediaQuery(max, $mobile) {
    background-size: 364px;
    background-position: bottom;
    padding: 2rem 1rem 10rem;
  }

  @include mediaQuery(max, 500px) {
    padding-bottom: 8rem;
  }
}

.homeownerContent {
  margin: 0 auto;
  max-width: $maxContentWidth;

  @include mediaQuery(max, $mdDesktop) {
    max-width: 915px;
  }
}

.homeownerSubTitle {
  max-width: 475px;

  @include mediaQuery(max, $desktop) {
    max-width: 400px;
  }
  @include mediaQuery(max, $mobile) {
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }
}

.homeownerBtn {
  margin-top: 15px;
}

.subtitle {
  font-weight: 400;
}
