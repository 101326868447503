@import '../../../styles/styles';

.section {
  background-image: $white;

  @include mediaQuery(max, 1023px) {
    padding: 0;
  }
}

.content {
  margin: 0 auto;
  max-width: $maxContentWidth;

  @include mediaQuery(max, $mdDesktop) {
    max-width: 915px;
  }

  @include mediaQuery(max, 1023px) {
    max-width: 100%;
    padding: 3rem 0 1rem;
  }
}

.recommendedTitle {
  @include mediaQuery(max, 1023px) {
    margin-left: 3rem;
  }

  @include mediaQuery(max, $mobile) {
    margin: 0 1rem;
  }
}

.travelDetails {
  max-width: 85%;

  @include mediaQuery(max, 1085px) {
    max-width: 100%;
  }
}
