@import 'styles/styles';

.banner {
  width: 100%;
  height: 46px;
  background-color: $glacier;
  display: flex;
  align-items: center;
  padding: 8px 8px 8px 38px;
  overflow: hidden;

  @include mediaQuery(max, 635px) {
    height: 75px;
  }

  @include mediaQuery(max, $smallMobile) {
    height: 90px;
  }

  .closeButton {
    background: transparent;
    width: 30px;
    min-width: 30px;
    padding: 8px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: normal;

    &:focus {
      outline: auto;
    }

    @include mediaQuery(max, $mobile) {
      align-items: flex-start;
      padding-top: 0;
    }
  }
}
