@import '../../styles/styles';

.reviewWrapper {
  display: flex;
  flex: 0 0 100%;
  position: relative;

  @include mediaQuery(max, $tablet) {
    display: block;
  }
}

.reviewImage {
  border-radius: 0.25rem;
  width: 60%;
  overflow: hidden;

  & > div {
    width: 100%;
    height: 100%;
  }

  @include mediaQuery(max, $tablet) {
    height: 300px;
    width: 100%;
  }

  @include mediaQuery(max, $mobile) {
    height: 250px;
  }
}

.reviewContentWrapper {
  background-color: $white;
  border-bottom: 6px solid $yellow;
  border-radius: 4px;
  box-shadow: $box-shadow;
  right: 15px;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  width: 520px;

  @include mediaQuery(max, $tablet) {
    margin: 0 auto;
    position: relative;
    right: 0;
    top: -35%;
    transform: translateY(35%);
    width: calc(100% - 6rem);
  }

  @include mediaQuery(max, $mobile) {
    width: calc(100% - 2rem);
  }
}

.reviewContent {
  padding: 55px 28px 42px 100px;
  position: relative;

  @include mediaQuery(max, $tablet) {
    padding: 25px 18px;
    text-align: center;
  }
}

.quote {
  left: 28px;
  position: absolute;
  top: 42px;
  width: 50px;

  @include mediaQuery(max, $tablet) {
    display: block;
    left: 0;
    margin: 0 auto 14px;
    position: relative;
    top: 0;
  }
}

.reviewContentQuote {
  font-size: 20px;
  font-weight: 300;
  line-height: 2.125rem;
  color: $blue-extra-dark;
  @include mediaQuery(max, $tablet) {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
}

.reviewerInfo {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 215px;

  @include mediaQuery(max, $tablet) {
    flex-direction: column;
    margin: 0 auto;
  }
}

.reviewerInfoSm {
  @extend .reviewerInfo;
  width: 178px;
}

.avatarImage {
  background-color: $grey-light;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  height: 53px;
  width: 53px;

  @include mediaQuery(max, $tablet) {
    margin-bottom: 5px;
  }
}

.avatarDetails {
  span {
    display: block;
    line-height: 20px;

    &:first-child {
      color: $blue-extra-dark;
      font-size: 1rem;
      font-weight: 600;
    }

    &:last-child {
      color: $casal;
      font-size: 0.8125rem;
    }
  }
}
