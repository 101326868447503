@import 'styles/styles';

.wrapper {
  background-color: $glacier;
}

.banner {
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  color: $headings;
  line-height: 1.5;

  a {
    color: inherit;
    text-decoration: underline;
  }

  * {
    display: inline-block;
    white-space: pre;
  }
}
