@import '../../../styles/styles';

.guestReviewSection {
  background-image: $linearGradient;

  @include mediaQuery(max, $tablet) {
    padding: 2rem 0 0;
  }

  @include mediaQuery(max, $mobile) {
    padding: 1rem 0 0;
  }
}

.guestContent {
  margin: 0 auto;
  max-width: $maxContentWidth;

  @include mediaQuery(max, 1023px) {
    padding: 3rem 0 1rem;
  }

  @include mediaQuery(max, $tablet) {
    max-width: 100%;
  }
}
