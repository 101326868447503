@import 'styles/styles';

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeIn {
  animation: fadeIn ease 0.3s;
}

section.regionalLinksSection {
  align-items: flex-start;
}

.regionalLinksWrapper {
  width: 100%;
}

.flexContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  margin: 0 auto;
  max-width: $maxContentWidth;

  &.block {
    display: block;
  }
}

.noSubTitle {
  margin-bottom: 1rem;
}

.col {
  // 3 columns, desktop
  @include mediaQuery(min, 768px) {
    width: 30%;
  }
  // 2 columns, tablet
  @include mediaQuery(max, 768px) {
    width: 45%;
  }
  // 1 column, mobile
  @include mediaQuery(max, 600px) {
    width: 100%;
  }

  .item:last-of-type {
    border-bottom: 2px solid $divider;
  }
}

.item {
  padding: calc(1rem - 3px) 0;
  border-top: 2px solid $divider;

  h3 {
    @include noWebkitHilight;

    cursor: pointer;
    font-family: $font-secondary;
    font-size: 22px;
    font-weight: 800;
    line-height: 1.18;
    color: $saldova;
    padding: 3px 0;
    outline-offset: -1px;
    outline-width: thin;
    display: flex;
    align-items: baseline;

    &.autoPageRegion {
      font-size: 18px;
    }
  }
}

.linksWrapper {
  display: flex;
  transition: all 0.3s ease;

  &.isOpen {
    margin-top: 1rem;
  }
}

.links {
  display: none;
  margin-left: 23px;

  &.isOpen {
    display: block;
    @extend .fadeIn;
  }

  li {
    list-style: none;

    &:not(:last-of-type) {
      margin-bottom: 0.5rem;

      @include mediaQuery(max, $mobile) {
        margin-bottom: calc(0.5rem + 15px);
      }
    }

    a {
      text-decoration: none;
      color: $blue-extra-dark;
      font-size: 16px;
      font-weight: 500;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.icon {
  margin-right: 10px;
}
