@import 'styles/styles';

/*
  TODO:  
  The following CSS code needs to be rewritten as it does not adhere
   to best practices and is considered poor quality code.
*/

.homePageSectionSubtitleStyle {
  font-size: 1.125rem;

  @include mediaQuery(max, $tablet) {
    line-height: 1.45rem;
  }

  @include mediaQuery(max, $mobile) {
    font-size: 0.9375rem;
    line-height: 1.25rem;
  }
}

.descriptionStyle {
  display: block;
  font-size: 18px;
  line-height: 27px;
  margin-top: 10px;
  width: 90%;

  @include mediaQuery(max, 1085px) {
    width: 100%;
  }
}

.section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  padding: 5rem;
  width: 100%;

  &:not(.homePageSection) {
    @include mediaQuery(max, 1085px) {
      display: block;
      padding: 4rem;
    }

    @include mediaQuery(max, 930px) {
      padding: 3rem;
    }

    @include mediaQuery(max, $mobile) {
      padding: 3rem 1rem;
    }
  }

  // Homepage video only
  &.homePageSection {
    background-image: $linearGradient;
    max-width: 100%;
    align-items: center;
    padding: 5rem;
    width: 100%;

    @include mediaQuery(max, 1085px) {
      display: block;
      padding: 3rem;
    }

    @include mediaQuery(max, 930px) {
      padding: 3rem;
    }

    @include mediaQuery(max, $mobile) {
      padding: 3rem 1rem;
    }

    .info {
      max-width: 100%;
      flex: 0 0 45%;

      .description {
        @extend .homePageSectionSubtitleStyle;
        & > p {
          @extend .homePageSectionSubtitleStyle;
        }
      }

      .link {
        background-color: $red;
        border-radius: 4px;
        color: $white;
        display: inline-block;
        font-weight: 600;
        padding: 16px 32px;
        margin: 0;
      }
    }

    .videoWrapper {
      box-shadow: none;
      flex: 0 0 52%;
      width: 100%;

      iframe {
        box-shadow: $box-shadow;
        min-width: 100%;
        border: none;
        height: 100%;
      }
    }
  }
}

.imgWrapper {
  box-shadow: $box-shadow;
  border: none;
  flex: 0 0 52%;
  position: relative;
  aspect-ratio: 16 / 9;
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: $maxContentWidth;
  width: 100%;

  @include mediaQuery(max, 1085px) {
    display: block;
    padding: 0;
  }

  @include mediaQuery(max, 930px) {
    padding: 0;
  }
}

.info {
  flex: 0 0 40%;
  max-width: 445px;

  @include mediaQuery(max, 1085px) {
    margin: 0 auto 16px;
    max-width: 100%;
  }
}

.description {
  @extend .descriptionStyle;

  & > p {
    @extend .descriptionStyle;
  }
}

.title {
  margin: 0;
  line-height: 2.75rem;
  font-size: 2.2rem;
  letter-spacing: -0.38px;
}

.videoWrapper {
  box-shadow: $box-shadow;
  flex: 0 0 55%;
  max-width: 605px;
  width: 284px;

  & > div {
    height: 100%;
  }

  @include mediaQuery(max, 1085px) {
    height: calc(56.25vw - 3.375rem);
    max-height: 350px;
    flex: none;
    margin: 0 auto;
    width: calc(100vw - 6rem - 1px);
    max-width: 625px;
  }

  @include mediaQuery(max, $mobile) {
    height: auto;
    padding: 0px;
    width: 100%;
  }

  iframe {
    height: 100%;
    width: 100%;
    z-index: 0;
    background-color: transparent;
  }
}

.label {
  margin-bottom: 6px;
}
