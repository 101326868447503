@import '../../styles/styles';

.guestCarouselArrowTouch {
  @include mediaQuery(max, 1023px) {
    display: flex;
  }

  @include mediaQuery(max, $tablet) {
    top: 55%;
    transform: translateY(-55%);
  }
}

.guestCarouselArrowRight {
  right: -35px;

  @include mediaQuery(max, 1000px) {
    right: -5px;
  }

  @include mediaQuery(max, $tablet) {
    right: 7px;
  }
}

.guestCarouselTrack {
  max-width: 100%;

  @include mediaQuery(max, 1023px) {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    padding: 0;
  }

  @include mediaQuery(max, $tablet) {
    height: 650px !important;
  }

  @include mediaQuery(max, $mobile) {
    height: 650px !important;
  }

  @include mediaQuery(max, 402px) {
    height: 700px !important;
  }

  @include mediaQuery(max, 350px) {
    height: 750px !important;
  }
}

.guestSliderWrapper {
  @include mediaQuery(max, 1023px) {
    position: relative;
  }
}

// Indicators
.indication {
  bottom: 10px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: 15px;
  width: 50px;
  color: $overlineText;

  @include mediaQuery(max, $tablet) {
    bottom: 2.5rem;
    margin: 15px auto 0;
    position: relative;
    right: 0;
  }

  @include mediaQuery(max, $tablet) {
    bottom: 3.5rem;
  }

  @include mediaQuery(max, 705px) {
    bottom: 3rem;
  }

  @include mediaQuery(max, $mobile) {
    bottom: 2.5rem;
  }

  @include mediaQuery(max, 350px) {
    bottom: 3rem;
  }
}

.dot {
  background-color: $grey-dot;
  border-radius: 50%;
  height: 8px;
  width: 8px;
}

.dotLight {
  @extend .dot;
  background-color: $white;
}

.dotActive {
  @extend .dot;
  background-color: $grey-dot-active;
  box-shadow: $grey-dot-shadow;
}
