@import 'styles/styles';

.banner {
  align-items: center;
  background-color: $evolveBlue;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 14px 24px;
  .trophy {
    margin-bottom: 2px;
    min-width: 30px;
    min-height: 30px;
  }
  .arrow {
    margin-left: 6px;
  }
  span {
    color: white;
    font-family: Nunito, sans-serif;
    font-size: 14px;
    font-weight: 800;
    margin-left: 4px;
  }
  @include mediaQuery(min, $mobile) {
    span {
      font-size: 16px;
    }
  }
  @include mediaQuery(max, 480px) {
    span {
      max-width: 325px;
    }
  }
}

.homebanner {
  padding: 20px 0;
}

.body * {
  display: inline;
  margin: 0;
  padding: 0;
  line-height: normal;
  text-align: center;

  @include mediaQuery(min, $tablet) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 6px;
  }
}

.body img {
  margin: 0 6px;

  @include mediaQuery(min, $tablet) {
    margin: 0;
  }
}
